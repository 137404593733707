import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconRegistryService } from '@buspro/web/core';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'buspro-admin-widgets',
  standalone: true,
  providers: [],
  host: {
    class: 'w-full',
  },
  imports: [FormsModule, NzButtonModule],
  template: ` <p class="text-2xl font-bold">Dashboard</p> `,
  styles: ``,
})
export class DashboardComponent implements OnInit {
  private readonly iconRegistry = inject(IconRegistryService);

  ngOnInit(): void {
    this.iconRegistry.loadIcons(['download']);
  }
}
