import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  withPreloading,
  withRouterConfig,
} from '@angular/router';

import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import en from '@angular/common/locales/en';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideServiceWorker } from '@angular/service-worker';
import { ApiModule } from '@buspro/web/apis';
import {
  HttpErrorInterceptor,
  LocalAuthService,
  SelectivePreloadingStrategy,
  TenantImpersonationInterceptor,
  WEB_CONFIG,
} from '@buspro/web/core';
import { provideLoadingBarInterceptor } from '@ngx-loading-bar/http-client';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';
import {
  provideTanStackQuery,
  QueryClient,
  withDevtools,
} from '@tanstack/angular-query-experimental';
import {
  AuthenticationProvider,
  LocalStorageProvider,
  NgxAuthUtilsModule,
  StorageProvider,
} from '@zupit-it/ng-auth';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '../environments/environment';
import { routes } from './admin.routes';

registerLocaleData(en);
export const adminConfig: ApplicationConfig = {
	providers: [
		provideClientHydration(),
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideTanStackQuery(new QueryClient(), withDevtools()),
		provideRouter(
			routes,
			withPreloading(SelectivePreloadingStrategy),
			withRouterConfig({
				paramsInheritanceStrategy: 'always',
				onSameUrlNavigation: 'reload',
			}),
			// withDebugTracing()
		),
		provideAnimationsAsync(),
		provideServiceWorker('ngsw-worker.js', {
			enabled: !isDevMode(),
			registrationStrategy: 'registerWhenStable:30000',
		}),
		provideFirebaseApp(() =>
			initializeApp({
				apiKey: 'AIzaSyAO0rzFPfL6u1mecuk5hoOHEQ7hjO6S5QM',
				authDomain: 'busprov2.firebaseapp.com',
				projectId: 'busprov2',
				storageBucket: 'busprov2.appspot.com',
				messagingSenderId: '1049134460984',
				appId: '1:1049134460984:web:718d09ec23d948b9b6b6cd',
				databaseURL: 'https://busprov2-default-rtdb.firebaseio.com',
				measurementId: 'G-Y67P8GECKJ',
			}),
		),
		provideFirestore(() => getFirestore()),
		provideStorage(() => getStorage()),
		provideAnalytics(() => getAnalytics()),
		providePerformance(() => getPerformance()),
		provideNzI18n(en_US),
		provideAnimationsAsync(),
		provideLoadingBarInterceptor(),
		provideLoadingBarRouter(),
		// provideLoadingBar({ latencyThreshold: 100 }),
		importProvidersFrom([
			ApiModule.forRoot({
				rootUrl: environment.server,
			}),
			NzNotificationModule,
			NzModalModule,
			NgxPermissionsModule.forRoot(),
			LoggerModule.forRoot({
				level: NgxLoggerLevel.INFO,
				serverLogLevel: NgxLoggerLevel.ERROR,
			}),
			NgxAuthUtilsModule.forRoot({
				authenticationProvider: {
					provide: AuthenticationProvider,
					useClass: LocalAuthService,
					deps: [],
				},
				storageProvider: {
					provide: StorageProvider,
					useClass: LocalStorageProvider,
				},
				homeUrl: '/dashboard',
				noAuthRedirectUrl: '/passport/login',
				sessionExpiredRedirectUrl: '/passport/login',
				authenticationHeader: 'Authorization',
				tokenType: 'Bearer',
			}),
		]),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: TenantImpersonationInterceptor,
		},
		{ provide: WEB_CONFIG, useValue: environment },
		provideServiceWorker('ngsw-worker.js', {
			enabled: !isDevMode(),
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
};
