import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import {
  IconRegistryService,
  LocalAuthService,
  preloadCommonAntIcons,
} from '@buspro/web/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { NgxAuthService } from '@zupit-it/ng-auth';
import { NGXLogger } from 'ngx-logger';
import { filter, map, mergeMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'buspro-root',
  standalone: true,
  imports: [RouterOutlet, LoadingBarHttpClientModule],
  template: `
    <main>
      <ngx-loading-bar color="red"></ngx-loading-bar>
      <router-outlet #o="outlet"></router-outlet>
      <!--      <angular-query-devtools [initialIsOpen]="false" />-->
    </main>
  `,
})
export class AppComponent implements OnInit {
  private ngxAuthService = inject(NgxAuthService);
  private authService = inject(LocalAuthService);
  private iconRegistry = inject(IconRegistryService);

  private logger = inject(NGXLogger);
  authUser$ = this.authService.currentUser$;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.ngxAuthService.initialize().subscribe();
  }

  ngOnInit(): void {
    preloadCommonAntIcons(this.iconRegistry);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          // this.authService.setInterruptedUrl(this.router.url);
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.titleService.setTitle('BusPro Admin | ' + event['title']);
      });
  }
}
