import { bootstrapApplication } from '@angular/platform-browser';
import { adminConfig } from './app/admin.config';
import { AppComponent } from './app/app.component';

// Sentry.init({
//   dsn: 'https://c2555fed20a8e8035cb23fad0dd1c9cd@o649484.ingest.us.sentry.io/4507981909000192',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

bootstrapApplication(AppComponent, adminConfig).catch((err) => console.error(err));
