import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TenantsAuthApi } from '@buspro/web/apis';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzResultComponent } from 'ng-zorro-antd/result';

@Component({
	selector: 'buspro-admin-reset-password-request-form',
	templateUrl: './admin-reset-password-request-form.component.html',
	styleUrls: ['./admin-reset-password-request-form.component.scss'],
	providers: [NzNotificationService],
	imports: [ReactiveFormsModule, NzResultComponent, RouterLink, NzInputDirective, NgIf],
	standalone: true,
})
export class AdminResetPasswordRequestFormComponent {
	private fb = inject(FormBuilder);
	private tenantApi = inject(TenantsAuthApi);

	form = this.fb.group({
		email: [null, [Validators.required]],
	});
	submitted = false;

	constructor(private notification: NzNotificationService) {}

	submit() {
		this.tenantApi
			.tenantsAuthControllerForgotPassword({
				body: { email: this.form.get('email')!.value! },
			})
			.subscribe(
				() => {
					this.submitted = true;
				},
				(error) => {
					this.notification.error('Ops! Something went wrong', error.error?.errorMessage);
				},
			);
	}
}
