import { Location, NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'buspro-admin-page-not-found',
	standalone: true,
	imports: [NgOptimizedImage, RouterLink, MatIconModule, MatIcon],
	template: `
    <section class="flex items-center pl-16 border bg-white">
      <div>
        <h1 class="text-6xl font-black">Page Not Found</h1>
        <div class="text-xl mt-3 mb-4 text-gray-500">
          The page you are looking for might have been removed or is temporarily
          unavailable.
        </div>
        <button (click)="location.back()" class="btn btn-lg btn-primary">
          <mat-icon>keyboard_backspace</mat-icon> Back
        </button>
      </div>
      <div>
        <img ngSrc="../../404.png" alt="404" height="1380" width="1840" />
      </div>
    </section>
  `,
	styles: ``,
})
export class PageNotFoundComponent {
	location = inject(Location);
}
