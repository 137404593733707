<div>
  <div *ngIf='!submitted else resultTpl'>
    <h1 class="mb-4 -mt-3 text-2xl lg:text-left font-semibold leading-snug tracking-tight text-left text-gray-900 md:text-4xl">Reset your password</h1>
    <div class='text-md text-gray-600  text-center lg:text-left font-semibold mb-4'>Provide your account email to help us reset your password</div>
    <div class="mt-8 space-y-10">

    </div>
    <form [formGroup]='form' (ngSubmit)='submit()' class="mt-12 space-y-4">
      <label class="block">
        <span class="block mb-1 text-xs font-medium text-gray-700">Your Email</span>
        <input nz-input formControlName='email' nzSize='large' class="form-input w-full" type="email" placeholder="Ex. james@bond.com" inputmode="email" required />
      </label>
      <button type='submit' [disabled]='form.invalid' class="w-full btn btn-primary btn-lg" value="Register">Reset my password <span class='fe fe-arrow-right'></span>  </button>
      <button routerLink='/passport/login' class="w-full btn btn-ghost btn-md" value="Register">Back to Login</button>
    </form>

  </div>
  <ng-template #resultTpl>
    <nz-result
      nzStatus="success"
      nzTitle="You password reset request has been submitted successfully!"
      nzSubTitle="A reset link has been sent to your inbox. Please open your inbox and follow the instructions"
    >
      <div nz-result-extra>
        <div class="flex justify-center my-3">
          <button class='btn btn-primary' routerLink='/passport/login' >Back to Login</button>
        </div>
      </div>
    </nz-result>
  </ng-template>
</div>

