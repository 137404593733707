import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { UsersService } from '@buspro/web/core';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzResultComponent } from 'ng-zorro-antd/result';

@Component({
	selector: 'buspro-admin-set-new-password-form',
	templateUrl: './admin-set-new-password-form.component.html',
	standalone: true,
	providers: [NzNotificationService],
	imports: [NgIf, ReactiveFormsModule, NzInputDirective, RouterLink, NzResultComponent],
	styleUrls: ['./admin-set-new-password-form.component.scss'],
})
export class AdminSetNewPasswordFormComponent {
	private fb = inject(FormBuilder);

	form = this.fb.group({
		password: [null, [Validators.required]],
		password_confirm: [null, [Validators.required]],
	});

	submitted = false;
	constructor(
		private notification: NzNotificationService,
		private userService: UsersService,
		private route: ActivatedRoute,
	) {}

	login() {
		if (this.form.invalid) {
			this.notification.success('Please provide your email and password', '');
			return;
		}

		if (this.form.get('password')?.value !== this.form.get('password_confirm')?.value) {
			this.notification.error('Passwords does not match', 'Please check your inputs');
			return;
		}

		const { token } = this.route.snapshot.queryParams;
		if (!token) {
			this.notification.error('Invalid token', '');
			return;
		}

		this.userService
			.setNewPassword({
				password: this.form.get('password')!.value!,
				password_confirm: this.form.get('password_confirm')!.value!,
				token,
			})
			.subscribe(
				() => {
					this.submitted = true;
					// this.router.navigateByUrl('/password/login')
					// this.notification.success('Password Changed', 'You can now log in with your new password')
				},
				(error) => {
					console.log(error);
					this.notification.error('Ops! Something went wrong', error.error?.errorMessage);
				},
			);
	}
}
