<div *ngIf='!submitted else resultTpl'>
  <h1 class="mb-4 -mt-3 text-2xl font-extrabold leading-snug tracking-tight text-left text-gray-900 md:text-4xl">Set a New Password</h1>
  <div class="mt-8 space-y-10">

  </div>
  <form [formGroup]='form' (ngSubmit)='login()' class="mt-12 space-y-4">
    <label class="block">
      <span class="block mb-1 text-xs font-medium text-gray-700">New Password</span>
      <input nz-input formControlName='password' class="form-input w-full" type="password"  required />
    </label>

    <label class="block">
      <span class="block mb-1 text-xs font-medium text-gray-700">Confirm your new password</span>
      <input nz-input formControlName='password_confirm' class="form-input w-full" type="password" required />
    </label>

    <button type='submit' class="w-full btn btn-primary btn-lg" value="Register">Reset my Password <span class='fe fe-arrow-right'></span>  </button>
    <button routerLink='/passport/login'  class="w-full btn btn-ghost btn-md" value="Register">Back</button>
  </form>
  <div class="pt-6 mt-6 text-sm font-medium text-gray-700 border-t border-gray-200">
  </div>
</div>

<ng-template #resultTpl>
  <nz-result
    nzStatus="success"
    nzTitle="Your password has been changed!"
    nzSubTitle="You can now login with your password"
  >
    <div nz-result-extra>
      <div class="flex justify-center my-3">
        <button class='btn btn-primary' routerLink='/passport/login' >Back to Login</button>
      </div>
    </div>
  </nz-result>
</ng-template>
