import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnInit, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Data, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TenantDetailDto } from '@buspro/shared/dtos';
import { AdminMenuService } from '@buspro/web/admin/core';
import { AppService, TenantService } from '@buspro/web/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzContentComponent, NzLayoutComponent } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzResultComponent } from 'ng-zorro-antd/result';
import { AvatarModule } from 'ngx-avatars';
import { BehaviorSubject } from 'rxjs';
import { TenantsSidemenuComponent } from './tenants-sidemenu.component';

@Component({
	selector: 'buspro-admin-tenants-layout',
	standalone: true,
	imports: [
		RouterOutlet,
		AsyncPipe,
		NzMenuModule,
		RouterLink,
		NzIconModule,
		NgIf,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		TenantsSidemenuComponent,
		NzResultComponent,
		NzLayoutComponent,
		NzContentComponent,
		AvatarModule,
		NgClass,
		RouterLinkActive,
	],
	providers: [TenantService],
	template: `
    <nz-layout
      *ngIf="tenant$ | async as currentTenant; else resolverErrorMessage"
      class="min-h-screen"
    >
      <div class="navbar bg-base-100 fixed z-40" [ngClass]="headerWidth()">
        <div class="flex-1">
          <a
            [routerLink]="'/tenants/context/' + currentTenant.id + '/overview'"
            class="flex items-center gap-2 text-xl pl-2 text-primary font-bold hover:no-underline"
          >
            <ngx-avatars
              [name]="currentTenant.name"
              [size]="32"
              [src]="currentTenant.logoUrl"
            />
            {{ currentTenant.name }}
          </a>
        </div>
        <div class="flex-none">
          <ul class="menu menu-horizontal px-1">
            <li>
              <a
                [routerLink]="
                  '/tenants/context/' + currentTenant.id + '/overview'
                "
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fe fe-home"></i>
                Overview
              </a>
            </li>

            <li hidden>
              <a
                [routerLink]="
                  '/tenants/context/' + currentTenant.id + '/subscription'
                "
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fe fe-credit-card"></i>
                Subscription
              </a>
            </li>

            <li hidden>
              <a
                [routerLink]="
                  '/tenants/context/' + currentTenant.id + '/billing'
                "
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <i class="fe fe-file-text"></i>
                Billing
              </a>
            </li>

            <li hidden>
              <details>
                <summary>
                  <i class="fe fe-dollar-sign"></i>
                  Payments
                </summary>
                <ul class="p-2 bg-base-100 rounded-box shadow-lg">
                  <li>
                    <a
                      [routerLink]="
                        '/tenants/context/' +
                        currentTenant.id +
                        '/payments/history'
                      "
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: false }"
                    >
                      <i class="fe fe-clock"></i>
                      Payment History
                    </a>
                  </li>
                  <li>
                    <a
                      [routerLink]="
                        '/tenants/context/' +
                        currentTenant.id +
                        '/payments/methods'
                      "
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: false }"
                    >
                      <i class="fe fe-credit-card"></i>
                      Payment Methods
                    </a>
                  </li>
                </ul>
              </details>
            </li>
          </ul>
        </div>
      </div>

      <nz-content class="mt-20">
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-2">
            <buspro-admin-tenants-sidemenu
              [tenantId]="currentTenant.id"
            ></buspro-admin-tenants-sidemenu>
          </div>
          <div class="col-span-10">
            <router-outlet></router-outlet>
          </div>
        </div>
      </nz-content>
    </nz-layout>

    <ng-template #resolverErrorMessage>
      <section
        class="bg-white p-8 min-h-full flex w-full h-full items-center justify-center"
      >
        <nz-result
          nzStatus="500"
          nzTitle="Data retrieval failed"
          nzSubTitle="Sorry, there was an error while loading the tenant details."
        >
          <div nz-result-extra>
            <div class="my-2 flex justify-center">
              <button class="btn btn-md" (click)="appService.reload()">
                Try Reloading the Page
              </button>
            </div>
            <div>
              If problem persist, kindly contact technical support for
              assistance.
            </div>
          </div>
        </nz-result>
      </section>
    </ng-template>
  `,
	styles: `
   .menu :where(li > *) {
      @apply text-base-content/70 transition-all duration-200;
    }

    .menu :where(li > .active) {
      @apply text-primary bg-primary/10 font-semibold border-l-4 border-primary;
    }

    .menu :where(li > *:hover) {
      @apply text-primary bg-primary/5;
    }

    .menu li details {
      @apply transition-all duration-200;
    }

    .menu li details[open] > summary {
      @apply text-primary bg-primary/10 font-semibold border-l-4 border-primary;
    }

    .menu li details[open] > ul {
      @apply bg-base-100 shadow-lg mt-2 rounded-lg;
    }

    .menu li details[open] > ul li > a.active {
      @apply text-primary bg-primary/10 font-semibold border-l-4 border-primary;
    }

    .menu li details[open] > ul li > a:hover {
      @apply text-primary bg-primary/5;
    }

    .navbar {
      @apply shadow-sm border-b border-base-200;
    }
  `,
})
export class TenantsLayoutComponent implements OnInit {
	private menuService = inject(AdminMenuService);
	private route = inject(ActivatedRoute);
	appService = inject(AppService);
	resolveFailed = false;
	tenant$ = new BehaviorSubject<TenantDetailDto | null>(null);
	headerWidth = computed(() => (this.menuService.collapsed() ? 'w-[calc(100%-108px)]' : 'w-[calc(100%-294px)]'));

	ngOnInit(): void {
		this.route.data.subscribe((data) => this.resolvePageData(data));
	}

	resolvePageData(data: Data) {
		if (data['tenant'].error) {
			this.resolveFailed = true;
		} else {
			const tenant: TenantDetailDto = data['tenant'];
			this.tenant$.next(tenant);
			this.appService.currentTenant$.set({
				createdAt: tenant.createdAt,
				status: tenant.status,
				id: tenant.id,
				address: '',
				name: tenant.name,
				logoUrl: tenant.logoUrl,
				admin: {
					email: tenant.admin.email,
					firstName: tenant.admin.firstName,
					surname: tenant.admin.surname,
					isMainContactPerson: true,
					role: tenant.admin.role,
					phoneNumber: tenant.admin.phoneNumber,
				},
				mdvrCompanyId: tenant.mdvrCompanyId,
			});
		}
	}
}
