import { Component, Input, computed, inject, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { AppService } from '@buspro/web/core';
import { AvatarModule } from 'ngx-avatars';
import { AdminMenuItemComponent } from './admin-menu-item.component';
import { CompanyStatusComponent } from './company-status.component';

export type MenuItem = {
	icon: string;
	label: string;
	route?: string;
	subItems?: MenuItem[];
	isTenantSpecific: boolean;
};

@Component({
	selector: 'buspro-admin-sidenav',
	standalone: true,
	imports: [MatListModule, MatIconModule, AvatarModule, AdminMenuItemComponent, CompanyStatusComponent],
	template: `
    @if (currentTenant()) {
    <div class="pt-8 text-center flex flex-col items-center">
      <ngx-avatars
        [size]="profilePicSize()"
        [name]="currentTenant()!.name"
        [src]="currentTenant()!.logoUrl"
      ></ngx-avatars>
      <div
        [style.height]="'3rem'"
        [class.!h-0]="sideNavCollapsed()"
        [class.opacity-0]="sideNavCollapsed()"
      >
        <div class="font-bold ">{{ currentTenant()!.name }}</div>
        <buspro-admin-company-status
          [status]="currentTenant()!.status"
        ></buspro-admin-company-status>
      </div>
    </div>
    }

    <mat-nav-list>
      @for (menuItem of menuItems(); track menuItem) {
      <buspro-admin-menu-item
        [item]="menuItem"
        [collapsed]="sideNavCollapsed()"
      ></buspro-admin-menu-item>
      }
    </mat-nav-list>
  `,
	styles: `

    .menu-item {
      border-left: 5px solid;
      border-left-color: rgba(0, 0, 0, 0);
    }

    .selected-menu-item {
      border-left-color: var(--primary-color);
      background: rgba(0, 0, 0, 0.05)
    }

    :host * {
      transition: all 300ms ease-in-out;
    }
  `,
})
export class AdminSidenavComponent {
	appService = inject(AppService);
	currentTenant = this.appService.currentTenant$;

	menuItems = signal<MenuItem[]>([
		{
			icon: 'widgets',
			label: 'Dashboard',
			route: '/dashboard',
			isTenantSpecific: false,
		},
		{
			icon: 'domain',
			label: 'Tenants',
			route: `/tenants`,
			isTenantSpecific: true,
		},
		{
			icon: 'explore_nearby',
			label: 'Terminals & Bus Stops',
			route: '/standard-places',
			isTenantSpecific: false,
		},
		{
			icon: 'directions_bus',
			label: 'Bus Types',
			route: '/bus-types',
			isTenantSpecific: false,
		},
		{
			icon: 'supervisor_account',
			label: 'Users',
			route: '/locals/users',
			isTenantSpecific: false,
		},
	]);

	sideNavCollapsed = signal(false);
	profilePicSize = computed(() => (this.sideNavCollapsed() ? 32 : 100));

	@Input() set collapsed(val: boolean) {
		this.sideNavCollapsed.set(val);
	}
}
