import { DatePipe, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SetupUserPasswordDto } from '@buspro/shared/dtos';
import { UsersService, WEB_CONFIG, WebAppEnvironment } from '@buspro/web/core';
import { NzAlertComponent } from 'ng-zorro-antd/alert';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzResultComponent } from 'ng-zorro-antd/result';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'admin-setup-password',
	templateUrl: './setup-local-account-password.component.html',
	styleUrls: ['./setup-local-account-password.component.scss'],
	imports: [
		NgOptimizedImage,
		NgIf,
		NzAlertComponent,
		ReactiveFormsModule,
		NzResultComponent,
		RouterLink,
		DatePipe,
		NzInputDirective,
	],
	standalone: true,
})
export class SetupLocalAccountPasswordComponent implements OnInit {
	private fb = inject(FormBuilder);
	protected readonly environment = inject<WebAppEnvironment>(WEB_CONFIG);

	submitted$$ = new BehaviorSubject<number>(0);
	submitted = false;
	form = this.fb.group({
		email: [{ value: '', disabled: true }, [Validators.required]],
		password: ['', [Validators.required]],
		passwordConfirm: ['', [Validators.required]],
	});

	userId!: string;
	name!: string;

	websiteUrl = this.environment.publicWebsiteUrl;
	date = new Date();

	constructor(
		private route: ActivatedRoute,
		private notification: NzNotificationService,
		private usersService: UsersService,
	) {}

	ngOnInit(): void {
		const { uid, name, email } = this.route.snapshot.queryParams;

		if (!uid) {
			// Show error page
			this.notification.error('Ops! Something went wrong', 'Please contact support');
			return;
		}

		this.userId = uid;
		this.form.get('email')?.setValue(email);
		this.name = name;
	}

	completeAccountSetup() {
		if (this.form.get('password')?.value !== this.form.get('passwordConfirm')?.value) {
			this.notification.error('Passwords does not match', 'Please check your password');
			return;
		}

		const payload: SetupUserPasswordDto = {
			userId: this.userId,
			password: this.form.get('password')!.value!,
		};
		console.log('payload', payload);

		this.usersService.completeUserAccount(payload).subscribe(
			() => {
				this.submitted$$.next(1);
				this.submitted = true;
			},
			(error) => {
				console.log(error);
				const note = {
					title: 'Ops! Something went wrong',
					content: error.error?.errorMessage,
				};
				this.notification.error(note.title, note.content);
			},
		);
	}
}
