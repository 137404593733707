import { DatePipe, NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserRolesEnum } from '@buspro/shared/common';
import { LocalUserDto } from '@buspro/web/apis';
import { LocalAuthService, WEB_CONFIG, WebAppEnvironment } from '@buspro/web/core';
import { EnvBannerComponent } from '@buspro/web/shared';
import { FeInputErrorDirective } from '@fulleffect/ngx-tailings';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgxAuthService } from '@zupit-it/ng-auth';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NGXLogger } from 'ngx-logger';

@UntilDestroy()
@Component({
	selector: 'buspro-admin-login',
	standalone: true,
	imports: [
		NgOptimizedImage,
		DatePipe,
		ReactiveFormsModule,
		NgxLoadingModule,
		FeInputErrorDirective,
		FormsModule,
		ReactiveFormsModule,
		EnvBannerComponent,
	],
	template: `
    <!-- <ngx-loading [show]="loading"></ngx-loading> -->

    <div
      class="login-bg py-10 lg:py-24  w-screen h-screen justify-center items-center"
    >
      <div
        class="relative flex flex-col lg:flex-row bg-white rounded-lg shadow-2xl overflow-hidden mx-auto max-w-sm lg:max-w-5xl"
      >
        <buspro-env-banner [label]="env"></buspro-env-banner>
        <!--        <div class="absolute right-0 top-0 h-16 w-16">-->
        <!--          <div-->
        <!--            class="absolute transform rotate-45 bg-green-600 text-center text-white font-semibold py-1 right-[-35px] top-[32px] w-[170px]"-->
        <!--          >-->
        <!--            STAGING-->
        <!--          </div>-->
        <!--        </div>-->

        <div
          class="lg:block h-32 lg:h-auto lg:w-1/2 bg-cover"
          style="background-image:url('theme3.jpg')"
        >
          <div
            class="hidden bg-gradient-to-br from-transparent to-blue-900 h-full w-full lg:relative  lg:p-12 lg:flex flex-col-reverse"
          >
            <p class="mt-4 leading-relaxed text-lg font-medium text-white/80">
              For BUSPRO management and employees internal use only.
            </p>

            <h2
              class="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl"
            >
              Administrative Portal 📍
            </h2>
          </div>
        </div>
        <div class="w-full p-8 lg:w-1/2">
          <div class="w-64 mx-auto">
            <img
              ngSrc="logo-no-border.png"
              alt="Logo"
              height="188"
              width="600"
            />
          </div>
          <h2 class="text-2xl font-semibold text-gray-700 text-center mt-8">
            BUSPRO ADMIN
          </h2>
          <p class="text-xl text-gray-600 text-center">Welcome back!</p>

          <div class="mt-8 flex items-center justify-between">
            <span class="border-b w-1/5 lg:w-1/4"></span>
            <a href="#" class="text-xs text-center text-gray-500 uppercase"
              >sign in to continue</a
            >
            <span class="border-b w-1/5 lg:w-1/4"></span>
          </div>

          <form [formGroup]="form">
            <div class="mt-4">
              <label class="block text-gray-700 text-sm font-bold mb-2"
                >Email Address</label
              >
              <input
                formControlName="email"
                feInputError
                class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type="email"
              />
              <!--              <fe-input-error-display [control]="email" controlName="Email address"></fe-input-error-display>-->
            </div>
            <div class="mt-4">
              <div class="flex justify-between">
                <label class="block text-gray-700 text-sm font-bold mb-2"
                  >Password</label
                >
                <!--              <a href="#" class="text-xs text-gray-500">Forget Password?</a>-->
              </div>
              <input
                formControlName="password"
                feInputError
                class="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none"
                type="password"
              />
              <!--              <fe-input-error-display [control]="password" controlName="Password"></fe-input-error-display>-->
            </div>
            <div class="mt-8">
              <button
                (click)="submit()"
                class="bg-gray-700 btn btn-primary text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600"
              >
                Login
              </button>
            </div>
          </form>

          <div
            class="pt-6 mt-6 text-md lg:text-base text-center font-medium text-gray-700"
          >
            Having problems logging into your account? Please contact Buspro
            Tech for support.
          </div>

          <div class="mt-8 text-gray-400 text-center text-sm">
            Copyright &nbsp; &#64;{{ currentDate | date : 'YYYY' }}. BusPro. All
            rights reserved.
          </div>
        </div>
      </div>
    </div>
  `,
	styles: `
    .login-bg {
      background-color: #cccccc;
      background-image: url("/images/admin_login_bg.svg");
      background-attachment: fixed;
      background-size: cover;
    }
  `,
})
export class AdminLoginComponent {
	private readonly notification = inject(NzNotificationService);
	private readonly authService = inject(LocalAuthService);
	private readonly logger = inject(NGXLogger);
	private fb = inject(NonNullableFormBuilder);
	protected readonly environment = inject<WebAppEnvironment>(WEB_CONFIG);
	private readonly ngxAuthService = inject(NgxAuthService);
	private readonly router = inject(Router);

	protected readonly ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
	currentDate = new Date();
	public loading = false;

	email = new FormControl<string>('', [Validators.required, Validators.email]);
	password = new FormControl<string>('', [Validators.required]);
	form = this.fb.group({
		email: this.email,
		password: this.password,
	});

	get env() {
		return this.environment.env;
	}

	snackBar = inject(MatSnackBar);

	submit() {
		this.loading = true;
		if (this.form.invalid) {
			this.snackBar.open('Please provide a correct email and password', 'CLOSE', { duration: 3000 });
			this.loading = false;
			return;
		}

		const email: string = this.email.value || '';
		const password: string = this.password.value || '';

		this.ngxAuthService.login(this.form.getRawValue()).subscribe(
			(user: LocalUserDto) => {
				this.logger.log('The user is logged in', user);
				const homeRoute = this.authService.homeRoutePerRole(user.role as UserRolesEnum);
				this.logger.log('Navigating user to ', homeRoute);
				this.router.resetConfig(this.router.config);
				this.router.navigateByUrl(homeRoute).catch((reason) => {
					this.logger.error('Navigation error: ', reason);
				});

				// window.location.href = homeRoute;
			},
			(error) => {
				this.logger.error('Login failed', error);
				this.notification.error('Login Failed', 'Invalid credentials or an error occurred. Please try again.');
			},
		);
	}
}
