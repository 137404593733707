import { AsyncPipe, DatePipe, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, inject, signal } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SetupUserPasswordDto } from '@buspro/shared/dtos';
import { LocalUserDto, LocalUsersApi } from '@buspro/web/apis';
import { ApiError, WEB_CONFIG, WebAppEnvironment } from '@buspro/web/core';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { NzAlertComponent } from 'ng-zorro-antd/alert';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzResultComponent } from 'ng-zorro-antd/result';
import { NzSkeletonComponent } from 'ng-zorro-antd/skeleton';
import { NgxLoadingModule } from 'ngx-loading';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Component({
	selector: 'admin-activate-tenant',
	templateUrl: './activate-local-account.component.html',
	styleUrls: ['./activate-local-account.component.scss'],
	imports: [
		NgOptimizedImage,
		NgIf,
		AsyncPipe,
		NzAlertComponent,
		ReactiveFormsModule,
		NzResultComponent,
		RouterLink,
		DatePipe,
		NzInputDirective,
		NgxLoadingModule,
		NzSkeletonComponent,
	],
	standalone: true,
})
export class ActivateLocalAccountComponent implements OnInit {
	private fb = inject(FormBuilder);
	private logger = inject(NGXLogger);
	private localUsersApi = inject(LocalUsersApi);
	protected readonly environment = inject<WebAppEnvironment>(WEB_CONFIG);

	submitted$$ = new BehaviorSubject<number>(0);
	submitted = false;
	form = this.fb.group({
		email: [{ value: '', disabled: true }, [Validators.required]],
		password: ['', [Validators.required]],
		passwordConfirm: ['', [Validators.required]],
	});

	user$ = new BehaviorSubject<LocalUserDto | null>(null);

	websiteUrl = this.environment.publicWebsiteUrl;
	date = new Date();

	activationToken = signal<string | null>(null);

	query = injectQuery(() => ({
		enabled: this.activationToken() !== null,
		queryKey: ['activation-details', this.activationToken()],
		queryFn: () =>
			lastValueFrom(
				this.localUsersApi.localUsersControllerGetLocalUserDetailsFromActivationToken({
					token: this.activationToken()!,
				}),
			),
		select: (data) => {
			console.log('DATA', data);
			this.form.get('email')?.setValue(data.email);
			this.user$.next(data);
		},
		throwOnError: (error, query) => {
			this.notification.error(error.message, error.name);
			throw new Error(error.message);
		},
		retry: false,
	}));

	constructor(
		private route: ActivatedRoute,
		public notification: NzNotificationService,
	) {}

	ngOnInit(): void {
		const { uid, cid, actkn } = this.route.snapshot.queryParams;

		this.logger.log('Getting activation details with token', actkn);

		this.activationToken.set(actkn);
	}

	completeAccountSetup() {
		if (this.form.get('password')?.value !== this.form.get('passwordConfirm')?.value) {
			this.notification.error('Passwords does not match', 'Please check your password');
			return;
		}

		const payload: SetupUserPasswordDto = {
			userId: this.user$.getValue()!.id!,
			password: this.form.get('password')!.value!,
		};
		console.log('payload', payload);

		this.localUsersApi.localUsersControllerCompleteUserAccountSetup({ body: payload }).subscribe(
			() => {
				this.submitted$$.next(1);
				this.submitted = true;
			},
			(error: ApiError) => {
				this.logger.error(error);
				this.notification.error(error.error.title, error.error.detail);
			},
		);
	}
}
