import { Route } from '@angular/router';
import { FullScreenLayoutComponent } from '@buspro/web/shared';
import { AnonUserGuard } from '@zupit-it/ng-auth';
import { ActivateLocalAccountComponent } from './activate-local-account/activate-local-account.component';
import { SetupLocalAccountPasswordComponent } from './setup-local-password/setup-local-account-password.component';

export const AdminOnboardingRoutes: Route = {
	path: 'passport',
	canActivate: [AnonUserGuard],
	component: FullScreenLayoutComponent,
	children: [
		{
			path: 'activate-local',
			component: ActivateLocalAccountComponent,
			data: { title: 'Activate your account' },
		},
		{
			path: 'setup-password',
			component: SetupLocalAccountPasswordComponent,
			data: { title: 'Setup Password' },
		},
	],
};
