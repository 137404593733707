<section *ngIf='userId' class='grid grid-cols-1 lg:grid-cols-2 h-screen'>
  <div class='w-full px-4 py-12 mx-auto bg-white xl:py-24 md:w-3/5 lg:w-4/5 xl:w-3/5'>

    <div>
      <div *ngIf='!submitted else resultTpl'>
        <a [href]='websiteUrl'>
          <div class='w-56 cursor-pointer mb-8'>
            <img alt='`Logo' height="188" [priority]="1" ngSrc='logo-no-border.png' width="600">
          </div>
        </a>


        <h1 class='mb-4 -mt-3 text-2xl font-extrabold leading-snug tracking-tight text-left text-gray-900 md:text-4xl'>
          Complete your account setup</h1>
        <nz-alert [nzDescription]='"Please setup a password for your account on BusPro"'
                  [nzMessage]='"Hello "+ name'></nz-alert>
        <div class='mt-8 space-y-10'>
          <!--      <div class="text-center border-b border-gray-200" style="line-height: 0px">-->
          <!--        <span class="p-2 text-xs font-semibold tracking-wide text-gray-600 uppercase bg-white" style="line-height: 0px">Or</span>-->
          <!--      </div>-->
        </div>
        <form (ngSubmit)='completeAccountSetup()' [formGroup]='form' class='mt-8 space-y-4'>
          <label class='block'>
            <span class='block mb-1 text-sm font-medium text-gray-700'>Your Account Email is</span>
            <input class='form-input w-full' formControlName='email' inputmode='email' nz-input
                   nzSize='large' placeholder='Ex. james@bond.com' required type='email' />
          </label>
          <label class='block'>
            <span class='block mb-1 text-sm font-medium text-gray-700'>Your Password</span>
            <input class='form-input w-full' formControlName='password' nz-input nzSize='large' placeholder='' required
                   type='password' />
          </label>
          <label class='block'>
            <span class='block mb-1 text-sm font-medium text-gray-700'>Your Password</span>
            <input class='form-input w-full' formControlName='passwordConfirm' nz-input nzSize='large' placeholder=''
                   required
                   type='password' />
          </label>
          <div>
            <button [disabled]='form.invalid' class='w-full mt-8 btn btn-primary btn-lg' type='submit' value='Register'>
              Complete Account
            </button>
          </div>
        </form>
        <div class='pt-6 mt-6 text-sm font-medium text-gray-700 border-t border-gray-200'>
          Already have an account?
          <a class='text-purple-700 hover:text-purple-900' routerLink='/passport/login'>Login</a>
        </div>
      </div>

      <ng-template #resultTpl>
        <nz-result
          nzStatus='success'
          nzSubTitle='You can login with your credentials on the login. Click the login button to sign in to your company dashboard'
          nzTitle='Your BusPro account setup has been completed successfully!'
        >
          <div nz-result-extra>
            <button class='btn btn-primary' routerLink='/passport/login'>Sign In to Company Portal</button>
            <button [routerLink]='websiteUrl' class='btn btn-ghost'>Back to Website</button>
          </div>
        </nz-result>
      </ng-template>
    </div>

    <ng-template #accountSetupAlreadyCompleted>
      <nz-result
        nzStatus='success'
        nzSubTitle='Your account setup has already been completed. Please sign in to continue or request for password reset if you have forgotten your password'
        nzTitle='Invalid Activation Request!'
      >
        <div nz-result-extra>
          <button class='btn btn-primary' routerLink='/passport/login'>Sign In to Company Portal</button>
          <button class='btn btn-ghost' routerLink='/passport/reset-password'>Reset my password</button>
        </div>
      </nz-result>
    </ng-template>

    <div class='mt-8 text-gray-400 text-center text-sm'>Copyright &nbsp; &#64;{{ date|date:'YYYY' }}. BusPro. All rights
      reserved.
    </div>
  </div>
  <div class='px-4 py-20 space-y-10 bg-gray-100 xl:py-32 md:px-40 lg:px-20 xl:px-40 bg-cover'
       style='background-image: url("app-screen.jpg")'>
  </div>
</section>

