import { Component, Input } from '@angular/core';
import { NzTagComponent } from 'ng-zorro-antd/tag';

@Component({
	selector: 'buspro-admin-company-status',
	standalone: true,
	imports: [NzTagComponent],
	template: `
    @switch (status) { @case ('Pending') {
    <nz-tag nzColor="red">{{ status }}</nz-tag>
    } @case ('Activated') {
    <nz-tag nzColor="success">{{ status }}</nz-tag>
    } @default {
    <nz-tag>{{ status }}</nz-tag>
    } }
  `,
})
export class CompanyStatusComponent {
	@Input() status: 'Pending' | 'Activated' | 'None' | string = 'none';
}
